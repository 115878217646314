// FIXME: style tokens
$primaryFont: monospace;
$primaryFontSize: 16px;

* {
  font-family: $primaryFont;
  font-size: $primaryFontSize;
  box-sizing: border-box;
}

// Normalise
body {
  margin: 0;
}
